import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { UserFacadeService } from '../stores/user';

import { UserRole } from './enums';
import { IUserRoles } from './interfaces';
import { UserResourceAccess } from './interfaces';
import { getUserRoles } from './permission.helper';
import { Permissions } from './permission.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  userRoles$: Observable<IUserRoles>;
  userPermissions$: Observable<{
    companies: Record<string, UserRole[]>;
    global: UserRole[];
  }>;
  userResourceAccess$: Observable<UserResourceAccess>;

  constructor(private userFacadeService: UserFacadeService) {
    this.userPermissions$ = this.userFacadeService.user$.pipe(map((user) => user.permission));

    this.userRoles$ = this.userFacadeService.user$.pipe(
      map((user) => user.permission),
      filter(Boolean),
      map((permissions: Permissions) => getUserRoles(permissions)),
    );

    this.userResourceAccess$ = this.userFacadeService.user$.pipe(
      map((user) => user.resourceAccess),
      filter(Boolean),
    );
  }
}
