import { Permissions } from './permission.interface';
import { UserRole } from './enums';

export const emptyPermissionMock: Permissions = {
  companies: {},
  global: [],
};

export const companyMemberPermissionMock: Permissions = {
  companies: {
    '61af181a95e82835ee7d6f78': [UserRole.USER_COMPANY],
  },
  global: ['001G', '001W', '001U', '999G', '001M', '999P', '999M'],
};

export const noCompanyMemberPermissionMock: Permissions = {
  companies: {},
  global: ['001G'],
};

export const noAdminPermissionMock: Permissions = {
  companies: {},
  global: ['001W', '001M', '001G'],
};

export const adminPermissionMock: Permissions = {
  companies: {
    '61af181a95e82835ee7d6f78': [UserRole.USER_COMPANY],
  },
  global: ['001G', '001W', '001U', '999G', '999W', '001M', '999P', '999M'],
};
